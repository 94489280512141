input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

@font-face {
  font-family: 'quicksand-regular';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Regular.ttf) format('woff2');
  
}


@font-face {
  font-family: 'quicksand-bold';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Bold.ttf) format('woff2');
  
}
@font-face {
  font-family: 'quicksand-regular';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Regular.ttf) format('woff2');
  
}

@font-face {
  font-family: 'quicksand-medium';
  src: local('MyFont'), url(../public/assets/fonts/Quicksand/static/Quicksand-Medium.ttf) format('woff2');
  
}

@font-face{
  font-family:'sourceSansPro-light' ;
  src: local('MyFont'), url(../public/assets/fonts/source_sans_pro/SourceSansPro-Light.ttf) format('woff2');
}
@font-face{
  font-family:'sourceSansPro-bold' ;
  src: local('MyFont'), url(../public/assets/fonts/source_sans_pro/SourceSansPro-Bold.ttf) format('woff2');
}

@font-face{
  font-family:'sourceSansPro-regular' ;
  src: local('MyFont'), url(../public/assets/fonts/source_sans_pro/SourceSansPro-Regular.ttf) format('woff2');
}
@font-face{
  font-family:'sourceSansPro-semibold' ;
  src: local('MyFont'), url(../public/assets/fonts/source_sans_pro/SourceSansPro-SemiBold.ttf) format('woff2');
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}